// 拟任命人员
import { tongke } from '@/axios'
class Appointment {
  /**
   * @functionName import
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * @param { Number }  params.keyword  //关键词
   * @param { Number }  params.bmfs  //报名方式
   * @param { Number }  params.nrgs  //拟任公示，1-未公示|2-公示通过|3-公示不通过
   * @Description   // 拟任命人员列表
   * @return { Object }
   */
  list(params) {
    return tongke.get('/quxian/nrmry', { params })
  }

  /**
   * @functionName import
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * @param { Number }  params.keyword  //关键词
   * @param { Number }  params.hjlx  //报名方式
   * @param { Number }  params.jhksdn  //拟任公示，1-未公示|2-公示通过|3-公示不通过
   * @Description   // 拟任命人员-统计列表
   * @return { Object }
   */
  list_ss(params) {
    return tongke.get('/shengshi/nrmry/count', { params })
  }

  // 候选信息
  randomStatus(params) {
    return tongke.get('/quxian/sjcxnrmry/info', { params })
  }
  /**
   * @functionName
   * @param { Object }
   * @param { Object } params.ncxrs  拟抽选人数
   * @param { Object } params.cxly  抽选来源
   * @Description 随机抽选(开始)
   * @return { Object }
   */
  start(params) {
    return tongke.get('/quxian/sjcxnrmry/start', { params })
  }
  // 抽选进度
  progress() {
    return tongke.get('/quxian/sjcxnrmry/progress')
  }
  // 抽选结果
  stop() {
    return tongke.get('/quxian/sjcxnrmry/finish')
  }
  /**
   * @functionName publicView
   * @param { Object } params.ok  1-通过|0-不通过
   * @param { Object } params.sfzhm 身份证号码，批量通过传数组，不支持批量不通过
   * @param { Object } params.gsbtgyy  如果不通过，请填写不通过原因
   * @Description 拟任公示
   * @return { Object }
   */
  publicView(params) {
    return tongke.post('/quxian/nrgs', params)
  }

  /**
   * @functionName list_sfs
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * @param { Number }  params.keyword  //关键词
   * @param { Number }  params.bmfs  //报名方式
   * @Description   // 司法所-拟任命人员列表
   * @return { Object }
   */
  list_sfs(params) {
    return tongke.get('/sifasuo/nrmry', { params })
  }

  /**
   * @functionName list_nrgs_sfs
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * @param { Number }  params.keyword  //关键词
   * @param { Number }  params.bmfs  //报名方式
   * @Description   // 司法所-拟任公示列表
   * @return { Object }
   */
  list_nrgs_sfs(params) {
    return tongke.get('/sifasuo/nrgs', { params })
  }
  /**
   * @functionName list_nrgs_ss
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * @param { Number }  params.keyword  //关键词
   * @param { Number }  params.jhlx  //计划类型
   * @Description   // 省市-拟任公示列表
   * @return { Object }
   */
  list_nrgs_ss(params) {
    return tongke.get('/shengshi/nrgs/count', { params })
  }

  /**
   * @functionName list_nrgs_ss
   * @param { Object }  params
   * @param { Number }  params.page
   * @param { Number }  params.size
   * @param { Number }  params.xrjh  //计划类型
   * @param { Number }  params.zbjh  //计划类型
   * @param { Number }  params.bmfs  //计划类型
   * @param { Number }  params.keyword  //关键词
   * @Description   // 省市-拟任公示列表-拟任公示统计列表
   * @return { Object }
   */
  list_show_nrgs_ss(params) {
    return tongke.get('/shengshi/nrmry/info', { params })
  }
  // 通过拟任公示列表
  list_pass_nrgs_ss(params) {
    return tongke.get('/shengshi/nrgs/info', { params })
  }
}
export default new Appointment()
